import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AppBar, Hidden, IconButton, Toolbar } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import MenuIcon from "@material-ui/icons/Menu"
import { Link, navigate } from "gatsby"
import React from "react"
import "../styles/layout.css"
import Collapse from "@material-ui/core/Collapse"
import { rhythm, scale } from "../utils/typography"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: 1400,
    backgroundColor: "#1a1b21",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const Layout = ({ location, title, children }) => {
  let header
  const rootPath = `${__PATH_PREFIX__}/`
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerClose = () => {
    setMobileOpen(false)
  }

  const handleToggleList = () => {
    setOpen(!open)
  }

  const drawer = (
    <div>
      <Divider />
      <List>
        <ListItem button onClick={handleToggleList}>
          <ListItemText primary="Posts" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {[
              { path: "/", label: "All Posts" },
              { path: "/explore", label: "Explore" },
              { path: "/explore/technology", label: "Technology" },
              { path: "/explore/work", label: "Work" },
            ].map(tuple => {
              return (
                <ListItem
                  key={tuple.path}
                  button
                  className={classes.nested}
                  onClick={() => navigate(tuple.path)}
                >
                  <ListItemText primary={tuple.label} />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
        <ListItem button onClick={() => navigate("/recommended-books")}>
          <ListItemText primary={"Books"} />
        </ListItem>
      </List>
      <Divider />
    </div>
  )

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = ""
  }

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ color: "white" }} />
          </IconButton>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            The Everyday Developer
          </Link>
        </Toolbar>
      </AppBar>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar}></div>
          {drawer}
        </Drawer>
      </Hidden>
      <div className={classes.toolbar} />
      <header>{header}</header>
      <main>{children}</main>
      <footer className="footer-align footer-pad footer-style">
        © {new Date().getFullYear()} The Everyday Developer
        <p>
          Crafted with
          {` `}
          <FontAwesomeIcon icon={faHeart} />
          {` `}by
          {` `}
          <a
            href="https://github.com/philalem"
            target="_blank"
            rel="noopener noreferrer"
          >
            Phillip
          </a>{" "}
        </p>
      </footer>
    </div>
  )
}

export default Layout
